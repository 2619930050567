<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'

interface DropdownProps {
  modelValue: boolean
  position?: 'bottom-left' | 'bottom-right' | 'bottom' | 'top-left' | 'top-right' | 'top'
  openOnHover?: boolean
}

const { modelValue, position = 'bottom-left', openOnHover } = defineProps<DropdownProps>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const dropdownEl = ref()
const mouseLeaveTimeout = ref()

const closeDropdown = () => {
  emit('update:modelValue', false)
}

const clickHandler = ({ target }: MouseEvent) => {
  if (modelValue && !dropdownEl.value.contains(target))
    closeDropdown()
}

const mouseEnterHandler = () => {
  if (openOnHover && window.matchMedia('(hover: hover) and (pointer: fine').matches) {
    if (mouseLeaveTimeout.value)
      clearTimeout(mouseLeaveTimeout.value)

    emit('update:modelValue', true)
  }
}

const mouseLeaveHandler = () => {
  if (openOnHover && window.matchMedia('(hover: hover) and (pointer: fine').matches) {
    mouseLeaveTimeout.value = setTimeout(() => {
      emit('update:modelValue', false)
    }, 500)
  }
}

onMounted(async () => {
  document.addEventListener('click', clickHandler)
})

onUnmounted(() => {
  document.removeEventListener('click', clickHandler)
})

const style = {
  dropdown: 'relative',
  dropdownButton: 'block',
  dropdownContent: 'absolute z-10',
  position: {
    'top': 'bottom-full left-1/2 -translate-x-1/2',
    'bottom': 'top-full left-1/2 -translate-x-1/2',
    'top-left': 'bottom-full left-0 translate-x-0',
    'top-right': 'bottom-full right-0 translate-x-0',
    'bottom-left': 'top-full left-0 translate-x-0',
    'bottom-right': 'top-full right-0 translate-x-0',
    'left': 'right-full top-1/2 -translate-y-1/2',
    'right': 'left-full top-1/2 -translate-y-1/2',
  },
}
</script>

<template>
  <div
    ref="dropdownEl"
    :class="style.dropdown"
    @mouseenter="mouseEnterHandler"
    @mouseleave="mouseLeaveHandler"
  >
    <button
      type="button"
      :class="style.dropdownButton"
      @click="$emit('update:modelValue', !modelValue)"
    >
      <slot />
    </button>

    <transition :name="`fade-from-${position.split('-')[0]}`">
      <div
        v-if="modelValue"
        :class="[style.dropdownContent, style.position[position]]"
      >
        <slot name="content" :close-dropdown="closeDropdown" />
      </div>
    </transition>
  </div>
</template>
